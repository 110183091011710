import { useContext, useState, useEffect } from 'react';
import { Alert, Backdrop, Box, CSSObject, CssBaseline, Divider, IconButton, Theme, Toolbar, styled, useTheme } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { CarParkContext } from '../services/carpark.context';
import './FacilityList.css';
import FacilityList from './FacilityList';
import FacilityDetail from './FacilityDetail';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AboutList from './AboutList';
import { BarChart, Home } from '@mui/icons-material';
import ChartDialog from './ChartDialog';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const CarParks = () => {  
  const theme = useTheme();
  const { isMenuOpen, toggleMenu, isLoading, error, listItemClickCount, screenSize, selectedCarPark, setSelectedCarPark } = useContext(CarParkContext);
  const hasError = !!error;
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [openChartDialog, setOpenChartDialog] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplashScreen(false);
      clearTimeout(timer);
    }, 2000);
  });

  useEffect(() => {
    const orientationExist = 'orientation' in window; 
    const screenSizeToKeepDrawerOpen = 600;
    if (orientationExist) {
      if (window.orientation === 0 || window.orientation === 180) {
        var minSize = Math.min(screenSize.width, screenSize.height);
        if (minSize < screenSizeToKeepDrawerOpen) {
          toggleMenu(false);
        }    
      } 
    } else if (screenSize.width < screenSizeToKeepDrawerOpen) {
      toggleMenu(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, listItemClickCount]);

  const handleDrawer = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleDrawerClose = () => {
    toggleMenu(false);
  };

  const handleChartDialogOpen = () => {
    setOpenChartDialog(true);
  };

  const handleChartDialogClose = () => {
    setOpenChartDialog(false);
  };

  const handleHomeClick = () => {
    setSelectedCarPark(null);
  };

  if (showSplashScreen) {
    return (
      <Backdrop
        sx={{ color: '#fff', backgroundColor: '#EE780C', opacity: 0.1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>
        <img style={{maxWidth:'100%'}} src={require("../images/splash.png")} alt={"Sydney Commuter Parking"}/>
      </Backdrop>
    );
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={isMenuOpen}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                marginRight: 2,
              }}
            >
              <MenuIcon />
            </IconButton>
            <img style={{maxWidth:'100%', maxHeight:'56px'}} src={require("../images/banner.png")} alt={"Sydney Commuter Parking"}/>
            {!!selectedCarPark && (
              <>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                  <IconButton color="inherit" aria-label="home" onClick={handleHomeClick}>
                    <Home />
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                  <IconButton color="inherit" aria-label="chart" onClick={handleChartDialogOpen}>
                    <BarChart />
                  </IconButton>
                </Box>
                <ChartDialog
                  selectedValue={selectedCarPark}
                  open={openChartDialog}
                  onClose={handleChartDialogClose}
                />
              </>
            )}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={isMenuOpen}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {!isLoading && (<FacilityList showType="Metro" />)}
          <Divider />
          {!isLoading && (<FacilityList showType="Train" />)}
          <Divider />
          {!isLoading && (<FacilityList showType="Bus" />)}
          <Divider />
          {!isLoading && (<AboutList />)}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {isLoading && (<AutorenewIcon fontSize="large" className="rotate" />)}
          {hasError && (<Alert severity="error">{error}</Alert>)}
          {!hasError && !isLoading && (<FacilityDetail />)}
        </Box>
      </Box>
    );
  }

  
};

export default CarParks;