import { useContext, useState } from 'react';
import { CarParkContext } from '../services/carpark.context';
import { Alert, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { FacilityInfo, formatFacilityName, ReportDetail } from '../services/carpark.service';
import { BarChart, BarItem, BarLabelContext } from '@mui/x-charts';
import CurrentScreenSize from '../utility/CurrentScreenSize';
import { Close } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: FacilityInfo | null;
    onClose: () => void;
}

const ChartDialog = (props: SimpleDialogProps) => {
    const { selectedCarPark, retrieveReports, reportData, setReportData, chartError } = useContext(CarParkContext);
    const { onClose, selectedValue, open } = props;
    const [scroll] = useState<DialogProps['scroll']>('paper');
    const screenSize = CurrentScreenSize();
    const hasChartError = !!chartError;

    const handleClose = () => {
        onClose();
        setReportData(null);
    };

    if (open && reportData == null) {
        retrieveReports(selectedValue?.facility_id!);
    }

    const getXAxisData = (reportData: ReportDetail[]) => {
        return reportData.map(data => data.Time);
    };

    const getYAxisData = (reportData: ReportDetail[]) => {
        const result = reportData.map(data => Math.round((data.Occupied / data.TotalSpot) * 100));
        return result;
    };

    const getBarLabelForLast2HoursReport = (item: BarItem, context: BarLabelContext) => {
        return reportData!.Last2HoursReport[item.dataIndex].Occupied.toString();
    }

    const getBarLabelForLastFewMinutesReport = (item: BarItem, context: BarLabelContext) => {
        return reportData!.LastFewMinutesReport[item.dataIndex].Occupied.toString();
    }

    return (
        <Dialog onClose={handleClose} open={open} scroll={scroll} maxWidth={'xl'} fullScreen={true}>
            <DialogTitle>{formatFacilityName(selectedCarPark?.facility_name ?? "")}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
                >
                <Close />
            </IconButton>
            <DialogContent dividers={scroll === 'paper'} style={{paddingLeft:0, paddingRight:0}}>
                {hasChartError && (<Alert severity="error">{chartError}</Alert>)}
                {!hasChartError && !reportData && (<AutorenewIcon fontSize="large" className="rotate" />)}
                {!hasChartError && !!reportData && (
                    <>
                        <BarChart
                            xAxis={[{ data: getXAxisData(reportData.LastFewMinutesReport), scaleType: 'band'}]}
                            yAxis={[{
                                colorMap: {
                                    type:'ordinal', 
                                    colors: [],
                                    unknownColor: '#EE780C'
                                }}]}
                            series={[
                                {
                                    data: getYAxisData(reportData.LastFewMinutesReport),
                                    label: 'Occupancy (%) in last few minutes',
                                    color: '#EE780C'
                                },
                            ]}
                            width={screenSize.width - 20}
                            height={300}
                            grid={{ vertical: true, horizontal: true }}
                            barLabel={getBarLabelForLastFewMinutesReport}
                            borderRadius={5}
                        />
                        <BarChart
                            xAxis={[{ data: getXAxisData(reportData.Last2HoursReport), scaleType: 'band'}]}
                            yAxis={[{
                                colorMap: {
                                    type:'ordinal', 
                                    colors: [],
                                    unknownColor: '#EE780C'
                                }}]}
                            series={[
                                {
                                    data: getYAxisData(reportData.Last2HoursReport),
                                    label: 'Occupancy (%) in last 2 hours',
                                    color: '#EE780C'
                                },
                            ]}
                            width={screenSize.width - 20}
                            height={300}
                            grid={{ vertical: true, horizontal: true }}
                            barLabel={getBarLabelForLast2HoursReport}
                            borderRadius={5}
                        />
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ChartDialog;